import doctors from "../../api/doctor";

const state = {
  deviceData: [],
  patientsData: [],
  singlePatientData: [],
  singleDeviceData: [],
  loading: false,
  searchTerm: "",
};

const getters = {
  loadingStatus(state) {
    return state.loading;
  },
  getPatients(state) {
    return state.deviceData.reverse();
  },
  getAllPatientsOnly(state) {
    return state?.patientsData
      ?.filter((patient) => patient.role === "Customer")
      .reverse();
  },
  getAllDoctorsOnly(state) {
    return state?.patientsData
      ?.filter((patient) => patient.role === "Doctor")
      .reverse();
  },
  getSinglePatientData(state) {
    return state?.singlePatientData;
  },
  getSingleDeviceData(state) {
    return state?.singleDeviceData;
  },
  getMacAddress(state) {
    return state?.singleDeviceData?.map((data) => {
      return data?.macAddressFramed;
    });
  },
  filteredPatients: (state) => {
    if (!state.searchTerm) return state.deviceData.reverse();
    return state.deviceData
      .reverse()
      .filter((data) =>
        data.macAddressFramed.includes(state.searchTerm.trim().toUpperCase())
      );
  },
};

const mutations = {
  SET_LOADING_STATUS(state, loadingStatus) {
    state.loading = loadingStatus;
  },
  SET_PATIENTS_FOR_DOCTOR(state, deviceData) {
    state.deviceData = deviceData.map((data) => {
      return {
        id: data.deviceId,
        firstName:
          data.customerFirstName?.charAt(0).toUpperCase() +
          data.customerFirstName?.slice(1),
        lastName:
          data.customerLastName?.charAt(0).toUpperCase() +
          data.customerLastName?.slice(1),
        fullName: data.customerFirstName
          ? data.customerFirstName + " " + data.customerLastName
          : "--",
        doctorFirstName: data.doctorFirstName,
        doctorLastName: data.doctorLastName,
        macAddress: data.mac_address,
        macAddressFramed: data.mac_address_framed.toUpperCase(),
        name: data.deviceName,
        customerId: data.customerId,
        algodata: data.algoData,
        batData: data.batData,
        spo2data: data.spo2data,
        tempdata: data.tempdata,
        isOnline: data.is_online,
      };
    });
  },
  SET_PATIENTS_FOR_DOCTOR_LIST(state, deviceList) {
    state.deviceData = deviceList.map((data) => {
      return {
        id: data.deviceId,
        firstName:
          data.customerFirstName?.charAt(0).toUpperCase() +
          data.customerFirstName?.slice(1),
        lastName:
          data.customerLastName?.charAt(0).toUpperCase() +
          data.customerLastName?.slice(1),
        fullName: data.customerFirstName
          ? data.customerFirstName + " " + data.customerLastName
          : "--",
        macAddress: data.mac_address,
        macAddressFramed: data.mac_address_framed.toUpperCase(),
        name: data.deviceName,
        customerId: data.customerId,
      };
    });
  },
  SET_ALL_PATIENT(state, patients) {
    state.patientsData = patients.map((patient) => {
      return {
        id: patient.userId,
        firstName:
          patient.first_Name?.charAt(0).toUpperCase() +
          patient.first_Name?.slice(1),
        licenseNo: patient.licenseno,
        lastName:
          patient.last_Name?.charAt(0).toUpperCase() +
          patient.last_Name?.slice(1),
        fullName: patient?.first_Name
          ? patient?.first_Name + " " + patient?.last_Name
          : "--",
        email: patient.email,
        address: patient.Address,
        aadharcard: patient.adharcard,
        gender: patient.gender,
        dob: patient.DOB,
        mobileNo: patient.mobile_no,
        gstNo: patient.GSTNO,
        roleId: patient.roleId,
        role: patient.role,
      };
    });
  },
  SET_SINGLE_PATIENT(state, patient) {
    if (patient) {
      state.singlePatientData = {
        id: patient._id,
        firstName: patient.first_Name,
        lastName: patient.last_Name,
        email: patient.email,
        address: patient.address,
        aadharcard: patient.aadharcard,
        gender: patient.gender,
        dob: patient.DOB,
        mobileNo: patient.mobile_no,
        height: patient.height,
        weight: patient.weight,
        bloodPressure:
          patient.medical_history && patient.medical_history.lowBloodPressure
            ? `${patient.medical_history.highBloodPressure}/${patient.medical_history.lowBloodPressure}`
            : "",
        diabetics:
          patient.medical_history && patient.medical_history.diabetes
            ? patient.medical_history.diabetes
            : "",
        heartCondition:
          patient.medical_history && patient.medical_history.hypertension
            ? patient.medical_history.hypertension
            : "",
        emergencyContacts: patient.emergencyContacts || [],
        medical_history: patient.medical_history || {},
      };
    }
  },
  DELETE_PATIENT(state, patientId) {
    state.singlePatientData = state.singlePatientData.filter(
      (data) => data.id !== patientId
    );
  },
  SET_SINGLE_DEVICE(state, deviceData) {
    state.singleDeviceData = deviceData.map((device) => {
      return {
        id: device._id,
        name: device.name,
        macAddress: device.mac_address,
        manufactureMonth: device.manufacture_month_year,
        macAddressFramed: device.mac_address_framed,
        doctorFirstName: device.docterfirstName,
        doctorLastName: device.docterlastName,
        customerFirstName: device.customerfirstName,
        customerLastName: device.customerlastName,
        customerFullName: device?.customerfirstName
          ? device?.customerfirstName + " " + device?.customerlastName
          : "--",
        adminId: device.adminId,
        doctorId: device.doctorId,
        customerId: device.customerId,
        algodata: device.algodata,
        batdata: device.batdata,
        falldata: device.falldata,
        spo2data: device.spo2data,
        tempdata: device.tempdata,
        isOnline: device.is_online,
      };
    });
  },
};

const actions = {
  async getPatientsForDoctor({ commit }, id) {
    commit("SET_LOADING_STATUS", true);
    const res = await doctors.getAllDevicesOfDoctor(id);
    if (res.status === 200) {
      commit("SET_PATIENTS_FOR_DOCTOR", res.data.data);
      commit("SET_LOADING_STATUS", false);
    }
  },
  async getPatientsForDoctorList({ commit }, id) {
    commit("SET_LOADING_STATUS", true);
    const res = await doctors.getAllDevicesOfDoctorList(id);
    if (res.status === 200) {
      commit("SET_PATIENTS_FOR_DOCTOR_LIST", res.data.data);
      commit("SET_LOADING_STATUS", false);
    }
  },
  async getAllPatientsData({ commit }, id) {
    commit("SET_LOADING_STATUS", true);
    const res = await doctors.getAllPatientsData(id);
    if (res.status === 200) {
      commit("SET_ALL_PATIENT", res.data.data);
      commit("SET_LOADING_STATUS", false);
    }
  },
  async getSinglePatient({ commit }, id) {
    commit("SET_LOADING_STATUS", true);
    const res = await doctors.getSinglePatientData(id);
    if (res.status === 200) {
      commit("SET_SINGLE_PATIENT", res.data.data);
      commit("SET_LOADING_STATUS", false);
    }
  },
  async getSingleDevice({ commit }, id) {
    commit("SET_LOADING_STATUS", true);
    const res = await doctors.getSingleDeviceData(id);
    if (res.status === 200) {
      commit("SET_SINGLE_DEVICE", res.data.data);
      commit("SET_LOADING_STATUS", false);
    }
  },
  async deletePatient({ commit }, id) {
    commit("SET_LOADING_STATUS", true);
    const res = await doctors.deletePatient(id);
    if (res.status === 200) {
      console.log("deleted--", res.data);
      commit("DELETE_PATIENT", id);
      commit("SET_LOADING_STATUS", false);
    }
    return res.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
