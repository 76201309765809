import client from "./index";

export default {
  getPatientAlgoData: (params) =>
    client.get(`devices/getalgodata?userId=${params.customerId}`),
  getStepGraph: (params) =>
    client.get(
      `devices/getstepdata?userId=${params.customerId}&agrFunction=${params.agrFunction}&timePeriod=${params.timePeriod}&startDate=${params.startDate}&endDate=${params.endDate}`
    ),
  getBloodOxygenGraph: (params) =>
    client.get(
      `devices/getspo2data?userId=${params.customerId}&agrFunction=${params.agrFunction}&timePeriod=${params.timePeriod}&startDate=${params.startDate}&endDate=${params.endDate}`
    ),
  getBodyTempGraph: (params) =>
    client.get(
      `devices/gettempdata?userId=${params.customerId}&agrFunction=${params.agrFunction}&timePeriod=${params.timePeriod}&startDate=${params.startDate}&endDate=${params.endDate}`
    ),
  getHeartRateGraph: (params) =>
    client.get(
      `devices/gethrdata?userId=${params.customerId}&agrFunction=${params.agrFunction}&timePeriod=${params.timePeriod}&startDate=${params.startDate}&endDate=${params.endDate}`
    ),
  getPatientEcgData: (params) =>
    client.get(
      `https://api-report.accu.live/download-report?userId=${params.customerId}&startDate=${params.startDate}&endDate=${params.endDate}`
    ),
};
