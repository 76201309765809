import client from "./index";

export default {
  getAllDevices: () => client.get("/devices/viewalldevices/"),
  deleteSingleDevice: (params) =>
    client.delete(`/devices/removesingledevice?deviceId=${params}`),
  //add device(Admin)
  addDeviceData: (params) => client.post("/devices/", params),

  //check assign device to doctor
  checkDeviceAssignToDoctor: (params) =>
    client.patch("/devices/assign/doctor", params),

  //check assign device to customer
  checkDeviceAssignToCustomer: (params) =>
    client.patch("/devices/assign/customer", params),
  deviceAssignToCustomer: (params) =>
    client.post("/devices/deviceassigntocustomer", params),
};
