import client from "./index";

export default {
  getAllDevicesOfDoctor: (params) => client.get(`/doctors/${params}/dashboard`),
  getAllDevicesOfDoctorList: (params) =>
    client.get(`/doctors/${params}/device`),
  getAllPatientsData: (params) => client.get(`/users?doctorId=${params}`),
  getSinglePatientData: (params) => client.get(`/users/${params}`),
  getSingleDeviceData: (params) =>
    client.get(`/devices/viewsingledevicedetails/${params}`),

  //update single device details
  updateSingleDevice: (params, id) =>
    client.put(`/devices/updatesingledevicedetails?deviceId=${id}`, params),

  //delete patient
  deletePatient: (params) => client.delete(`/users/${params}`),
  //edit patient
  updatePatient: (params) => {
    return client.patch(`/users/${params.get("_id")}`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
