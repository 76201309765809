import axios from "axios";

const BASE_URL = process.env.VUE_APP_API_URL;
const api = axios.create({
  baseURL: BASE_URL,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("access_token");

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log("Authorization header set:", config.headers.Authorization);
    } else {
      console.log("No access token found in localStorage.");
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken) {
        try {
          const response = await axios.post(`${BASE_URL}/auth/refresh_token`, {
            refreshToken: refreshToken,
          });

          const { accessToken, refreshToken: newRefreshToken } =
            response.data.data.tokens;
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("refresh_token", newRefreshToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;

          return api(originalRequest);
        } catch (refreshError) {
          localStorage.clear();
          // Replace this.$router.push("/login") with window.location.href if not using Vue Router
          window.location.href = "/login";
        }
      } else {
        console.log("No refresh token found in localStorage.");
      }
    }

    return Promise.reject(error);
  }
);

export default api;
